<template>
  <div>
    <Banner />
    <Rule />
    <Footer />
    <Floor />
  </div>
</template>
<script>
import Floor from "@/components/Floor"
import Banner from "./models/Banner";
import Rule from "./models/Rule"
import Footer from "../Home/models/Footer";

export default {
  name: "Activity",
  components: { Footer, Banner, Rule, Floor },
  data() {
    return {};
  }
};
</script>
