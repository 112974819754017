<template>
  <div class="rule">
    <ul class="nav">
      <li
        v-for="(item, index) in navList"
        :key="index"
        @click="navClick(item, index)"
        :class="{ active: item.id === activeId }"
      >
        <span> {{ item.title }}</span>
      </li>
    </ul>
    <div class="main-img">
      <div v-for="temp in introduceList" :key="temp.id">
        <img :src="temp.path" alt="" />
        <span v-show="expectShow">敬请期待...</span>
      </div>
    </div>
  </div>
</template>
<script>
import rules from "@/assets/activity/rule.jpg";
import awards from "@/assets/activity/awards.jpg";
import judges from "@/assets/activity/judges.jpg";
import game1 from "@/assets/activity/game1.jpg";
import game2 from "@/assets/activity/game2.jpg";
import game3 from "@/assets/activity/game3.jpg";
import game4 from "@/assets/activity/game4.jpg";
import game5 from "@/assets/activity/game5.jpg";
import game6 from "@/assets/activity/game6.jpg";
const introductDefault = [
  { id: 1, path: rules },
  { id: 2, path: awards },
  { id: 3, path: judges }
];
export default {
  data() {
    return {
      navList: [
        { id: 1, title: "活动介绍" },
        { id: 2, title: "活动规则" },
        { id: 3, title: "作品展示" },
        { id: 4, title: "获奖作品" }
      ],
      activeId: 1,
      expectShow: false,
      navShow: false,
      introduceList: Object.assign({}, introductDefault),
      gameList: [
        { id: 1, path: game1 },
        { id: 2, path: game2 },
        { id: 3, path: game3 },
        { id: 4, path: game4 },
        { id: 5, path: game5 }
      ],
      noneList: [{ id: 1, path: game6 }]
    };
  },
  methods: {
    navClick(item, index) {
      this.activeId = item.id;
      if (item.id === 1) {
        this.expectShow = false;
        this.introduceList = Object.assign({}, introductDefault);
      } else if (item.id === 2) {
        this.expectShow = false;
        this.introduceList = this.gameList;
      } else if (item.id === 3 || item.id === 4) {
        this.expectShow = true;
        this.introduceList = this.noneList;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.rule {
  .nav {
    position: absolute;
    top: 1000px;
    width: 1022px;
    height: 80px;
    left: 50%;
    transform: translateX(-50%);
    background: url("~@/assets/activity/nav.png") no-repeat;
    background-size: 100% 100%;
    background-position: center 0;
    text-align: center;
    li {
      display: inline-block;
      margin: 10px 66px;
      cursor: pointer;
      span {
        color: #ffffff;
        font-size: 24px;
        line-height: 34px;
        text-shadow: 0px 1px 0px rgba(196, 194, 194, 0.4);
        background: linear-gradient(0deg, #ffffff 0%, #f7f0e4 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .active {
      span {
        color: #ffd100;
        font-size: 24px;
        line-height: 34px;
        text-shadow: 0px 1px 0px rgba(224, 203, 8, 0.4);
        background: linear-gradient(0deg, #ffd100 0%, #db951c 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .main-img {
    font-size: 0;
    margin-top: -40px;
    img {
      width: 100%;
      height: 100%;
    }
    span {
      color: rgb(129, 128, 122);
      position: absolute;
      top: 150%;
      left: 50%;
      display: block;
      font-size: 40px;
      transform: translateX(-50%);
      z-index: 9;
    }
  }
}
</style>
