<template>
  <div class="activity">
    <div class="link">
      <div class="enter">
        <img src="~@/assets/activity/enter.png" alt="" @click="intetClick">
      </div>
      <!-- <div class="reserve">
        <img src="~@/assets/activity/erweima.png" alt="">
        <img src="~@/assets/activity/iod.png" alt="" @click="iosBtn">
        <img src="~@/assets/activity/anz.png" alt="" @click="open">
        <img src="~@/assets/activity/top.png" alt="" @click="topBtn">
      </div> -->
    </div>
    <!-- <ReserveModels ref="ReserveModels" /> -->
  </div>
</template>
<script>
import ReserveModels from "@/components/ReserveModels";
export default {
  components: {
    ReserveModels
  },
  data() {
    return {};
  },
  methods: {
    intetClick() {
      this.$router.push({ path: "/" });
    },
    open() {
      this.$refs["ReserveModels"].showGame = true;
    },
    topBtn() {
      const top = window.offsetTop;
      document.documentElement.scrollTop = top;
      document.body.scrollTop = top;
    },
    iosBtn() {
      window.open("https://uri6.com/tkio/A7Znqia", "_self");
    }
  }
};
</script>
<style lang="scss" scoped>
.activity {
  width: 100%;
  height: 994px;
  background: url("~@/assets/activity/bg.jpg") no-repeat;
  background-size: 100%, 100%;
  .link {
    position: absolute;
    right: 24px;
    top: 32px;
    z-index: 2;
    .reserve {
      position: fixed;
      width: 100%;
      height: 349.1px;
      background: url("~@/assets/activity/floating_window.png") no-repeat;
      margin-left: 60px;
      margin-top: 194px;
      padding-top: 17px;
      img {
        display: block;
        margin: 8px 0;
        padding: 0 22px;
        cursor: pointer;
        &:nth-child(2) {
          margin-top: 16px;
        }
        &:last-child {
          margin-left: 34px;
        }
      }
    }
  }
}
</style>
